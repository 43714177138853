import React from 'react'
import styled from 'styled-components'
import { Link as GatsbyLink } from 'gatsby'

import Container from './Container'

import Logo from '../../static/csu-logo-4c_pos-web.png'

const Header = styled.div`
  background-color: ${props => props.theme.colors.white};
  margin-bottom: 30px;
`

const Wrapper = styled.div`
  border-bottom: 1px solid ${props => props.theme.border};
`

const Navigation = styled.ul`
  margin: 0;
  padding: 0;
  overflow: visible;
  list-style-type: none;
`

const Item = styled.li`
  display: block;
  font-size: 14pt;
  list-style-type: none;
  margin: 0 0 10px 0;
  padding: 0;

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    display: inline-block;
    margin: 0;
    padding: 0;
  }
`

const Link = styled(GatsbyLink)`
  color: ${props => props.active ? props.theme.colors.blue : props.theme.colors.black};
  display: block;
  cursor: pointer;
  padding: 16px 20px;
  border-bottom: 2px solid ${props => props.active ? props.theme.colors.blue : props.theme.colors.white};

  &:hover {
    border-bottom-color: ${props => props.theme.colors.green};
  }

  @media (min-width: ${props => props.theme.breakpoints.sm}px) {
    display: inline-block;
  }
`

export default ({ pathname }) => (
  <Header>
    <Container>
      <header>
        <GatsbyLink to='/'>
          <img
            src={Logo}
            css={{ width: '100%', maxWidth: '200px', margin: '20px 0 20px 10px', height: 'auto' }}
          />
        </GatsbyLink>
      </header>
    </Container>
    <Wrapper>
      <Container>
        <nav>
          <Navigation>
            <Item><Link to='/' active={pathname === '/'}>Blog</Link></Item>
            <Item><Link to='/gemeinderat' active={pathname === '/gemeinderat'}>Gemeinderat</Link></Item>
            <Item><Link to='/kontakt' active={pathname === '/kontakt'}>Kontakt</Link></Item>
            <Item><Link to='/impressum' active={pathname === '/impressum'}>Impressum</Link></Item>
          </Navigation>
        </nav>
      </Container>
    </Wrapper>
  </Header>
)