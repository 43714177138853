import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox'
import { Link as GatsbyLink } from 'gatsby'

import Container from './Container'
import DefaultH2 from './H2'
import DefaultP from './P'

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.gray};
  margin-top: 100px;
  padding: 50px 0;
`

const P = styled(DefaultP)`
  line-height: 1.7;
  padding-bottom: 30px;
`

const H2 = styled(DefaultH2)`
  color: ${props => props.theme.colors.white};
  border-bottom: 1px solid #444;
  font-weight: 400;
  margin-bottom: 30px;
  padding-bottom: 20px;
`

const Link = styled(GatsbyLink)`
`

const Footer = () => (
  <Wrapper>
    <Container>
      <Flex flexWrap='wrap'>
        <Box
          width={[ 1, 1/2 ]}
          px={3}
          pb={4}
        >
          <H2>Sprechzeiten des Bürgermeisters</H2>
          <P>
            <strong>Dienstags </strong><br />
            von 18 Uhr bis 19 Uhr<br />
            oder nach Vereinbarung
          </P>
          <P>
            Im Rathaus in Kastl<br />
            Kirchplatz 4
          </P>
          <P>
            Tel.: 09642 70711 o. 09642 70735<br />
            Email: poststelle@kemnath.de
          </P>
        </Box>
        <Box
          width={[ 1, 1/2 ]}
          px={3}
          pb={4}
        >
          <H2>Kontakt</H2>
          <P>
            CSU Ortsverband Kastl<br />
            info@kastl-csu.de
          </P>
          <H2 css={{ marginTop: '100px' }}>Navigation</H2>
          <P>
            <Link to='/datenschutzerklaerung'>Datenschutzerklärung</Link>
          </P>
        </Box>
      </Flex>
    </Container>
  </Wrapper>
)

export default Footer
